<template>
  <div class="flex items-center justify-between">
    <div>
      <p class="font-medium text-md text-grey-dark-1">{{ title }}</p>
      <p class="text-sm text-grey-light">{{ text }}</p>
    </div>
    <div class="flex items-center">
      <p class="text-sm-2 font-medium text-grey-light mr-2">Email</p>
      <p
        class="text-sm-2 font-medium mr-1 min-w-onoff"
        :class="alerts[name] ? 'text-blue-dark-1' : 'text-grey-light'"
      >
        {{ state }}
      </p>
      <Toggle :value="alerts[name]" :onChange="toggle" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Toggle from "../../../components/Toggle/Toggle.vue";
export default {
  components: { Toggle },
  name: "NotificationRow",
  props: {
    index: { type: Number, default: null },
    name: { type: String, default: null },
    title: { type: String, default: "New Assessment Alert" },
    text: {
      type: String,
      default: "Get notified of new assessments available for you",
    },
  },
  computed: {
    ...mapState({
      alerts: (state) => state.account.alerts,
    }),
    state() {
      return this.alerts[this.name] ? "On " : "Off";
    },
  },
  methods: {
    ...mapActions({
      setAlertStatus: "account/setAlertStatus",
    }),
    toggle() {
      this.setAlertStatus({
        alertName: this.name,
        value: !this.alerts[this.name],
      });
    },
  },
};
</script>

<style></style>
