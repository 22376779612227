<template>
  <VueToggles
    class="outline-none"
    :value="value"
    @click="onChange"
    checkedBg="#00A1D5"
    uncheckedBg="#E4EBF1"
    dotColor="#FFF"
    :width="40"
    :height="20"
    :reverse="true"
  />
</template>

<script>
import Vue from "vue";
import VueToggles from "vue-toggles";
Vue.component("VueToggles", VueToggles);

export default {
  name: "Toggle",
  props: {
    value: { type: Boolean, default: false },
    onChange: { type: Function, default: null },
  },
};
</script>

<style></style>
